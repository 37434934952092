<template>
    <div class="resultat-item" :class="item.service">
      <div class="transporteur"></div>
        <div class="jourheure">
          <div class="jour">{{ jour }}</div>
          <div class="heures">{{ heures }}</div>
          <div class="transporteurtxt">{{ transporteurText }}</div>
        </div>
       <div class="trajet" v-if="item.from && item.to">
          <div class="from">{{ item.from }}</div>
          <div class="to">{{ item.to }}</div>
       </div>
        <div class="places" v-if="item.places">{{ item.places }} places disponibles <span v-if="item.placesTotales">sur {{ item.placesTotales }}</span></div>
        <div class="message" v-if="item.message">{{ item.message }}</div>
       <p  class="lien">
        <a :href="item.url" target="_blank">Covoiturer</a>
        </p>
    </div>
</template>

<script>

import moment from 'moment'
moment.locale('fr-CA');
export default {
  name: 'ResultItem',
  props: {
    item: Object,
  },
  computed: {
    jour() {
return  this.item.jour && moment(this.item.jour).format('LL') || null; 
    },
    heures() {
return  this.item.jour && moment(this.item.jour).format('H:mm') || null; 
    },
    transporteurText() {
      let label = ""
      switch(this.item.service) {
        case "eckoride":
            label = "via EckoRide"; break;
            case "amigo":
            label = "via Amigo Express"; break;
             case "ridesharing":
            label = "via Covoiturage.ca"; break;
            case "poparide":
            label = "via Poparide"; break;
      }
      return label
    }
  }
}
</script>