import Vue from 'vue'
import App from './App.vue'

Vue.config.productionTip = false
let startMap = function () {
  new Vue({
    render: h => h(App),
  }).$mount('#resultats')
}
if (window.MAPLOADED) {

  startMap();
} else {
  window.startMap = startMap
}


window.showCovoiturage = function () {
  if (window.jQuery) {
    window.jQuery(".covoiturage-resultats").fadeIn()
  }
}


if (window.jQuery) {


  const $ = window.jQuery
  $(".covoiturage-resultats .close").on("click", function () {
    $(".covoiturage-resultats").fadeOut()
  })
  $(".covoiturage-resultats .inside").on("click", function (e) {
    e.stopImmediatePropagation()
  })
  $(".covoiturage-resultats").on("click", function () {
    $(".covoiturage-resultats").fadeOut()
  })
  $("body").on("change", ".searchinputs", function () {
    if (($("#searchtrajet").is(":checked")|| $("#searchcovoiturage").is(":checked")) && $(".googleResults").css("display") !== "none") {
      $(".main-content").addClass("pushLeft")
      
      if ($("#trajet").html()) {
      $("#trajet").show()
    }
    } else {
      $(".main-content").removeClass("pushLeft")
      $("#trajet").hide()
    }
  })
  $("body").on("click", "#trajet .close", function () {
    $("#trajet").fadeOut()
      window.hideRoutes()
  })

}