<template>
  <div id="resultats" :class="tri">

    
    <div id="fields_cov" class="fieldbox">
      <GeoField @change="changeFrom" placeholder="Origine" ref="from" />
      <button @click="swi" class="switch"></button>
      <GeoField @change="changeTo" placeholder="Destination" ref="to" />
<div class="boutons">
      <button @click="triggerCovoiturage" class="btn btn-sm btn-primary">Trouver un covoiturage</button>
      </div>
    </div>

    <div id="fields_trajet" class="fieldbox">
      <GeoField @change="changeFrom" placeholder="Origine" ref="from" />
      <button @click="swi" class="switch"></button>
      <GeoField @change="changeTo" placeholder="Destination" ref="to" />
<div class="boutons">
      <button @click="triggerGoogleSearch"  class="btn btn-sm btn-primary">Trouver un trajet</button>
    
      </div>
      <div class="googleResults">
        <div class="inside">
          <div class="close" @click="clearRoutes">&times;</div>
          <div class="bike" v-if="bikeRoutes">
            <span class="icon-velo"></span>
            <h3>Vélo</h3>
            <div
              class="route"
              v-for="(route, index) in bikeRoutes.routes"
              @click="()=>{ showRoute(bikeRoutes, index) }"
              :key="route.overview_polyline"
            >
              <strong>{{ displayTitle(route) }}</strong>
              <div>
                <span class="data">{{ route.legs[0].distance.text }}</span>
                <span class="data">{{ getTemps(route) }}</span>
              </div>
              <div class="copy">{{ route.copyrights }}</div>
            </div>
          </div>
          <div class="bus" v-if="busRoutes">
            <span class="icon-autobus"></span>
            <h3>Transport en commun</h3>
            <div
              class="route"
              v-for="(route, index) in busRoutes.routes"
              @click="()=>{ showRoute(busRoutes, index) }"
              :key="route.overview_polyline"
            >
              <strong>{{ displayTitle(route) }}</strong>
              <div>
                <span class="data">{{ getTemps(route) }}</span>
              </div>
              <div class="copy">{{ route.copyrights }}</div>
            </div>
          </div>
          <div v-if="current" v-html="current" class="searchBoxContent"></div>
        </div>
      </div>
    </div>

    <div class="resultats">
      <div class="tri">
        <button v-on:click="tri = 'chrono'">Chronologique</button>
        <button v-on:click="tri = 'service'">Par service</button>
      </div>
      <div class="loading" v-if="loading">
        <div class="spinner"></div>
      </div>
      <div class="nav">
        <a v-for="sec in section" :key="sec.id" :href="'#section-' + sec.id">{{ sec.label }}</a>
      </div>
      <div v-for="sec in section" :key="sec.id" :id="'section-' + sec.id">
        <h2 :class="sec.id">{{ sec.label }}</h2>
        <ResultItem v-for="item in sec.event" :key="item.id" :item="item" />
      </div>
      <div class="noresults" v-if="section.length===0 && !loading">Aucun resultat</div>
    </div>
  </div>
</template>

<script>
import GeoField from "./components/GeoField.vue";
import SearchFunction from "./components/Search.fct.js";
import ResultItem from "./components/ResultItem.vue";
import moment from "moment";
moment.locale("fr-CA");

export default {
  name: "app",
  data() {
    let infowindow = new window.google.maps.InfoWindow({
      content: ""
    });
    return {
      from: null,
      to: null,
      results: [],
      loading: 0,
      tri: "chrono",
      bikeRoutes: null,
      busRoutes: null,
      current: null,
      infowindow,
      directionsRenderer: new window.google.maps.DirectionsRenderer({
        draggable: false,
        map: window.map,
        suppressMarkers: true,
        suppressBicyclingLayer: true,
        //   suppressInfoWindows: true,
        routeIndex: 0
      })
    };
  },
  mounted() {
    let fields_cov = document.getElementById("fields_cov");
    let fields_trajet = document.getElementById("fields_trajet");
    let fieldsdrop_cov = document.getElementById("searchcovoituragepanel");
    let fieldsdrop_trajet = document.getElementById("searchtrajetpanel");
    if (fields_trajet && fieldsdrop_trajet) fieldsdrop_trajet.append(fields_trajet);
 if (fields_cov && fieldsdrop_cov) fieldsdrop_cov.append(fields_cov);

    window.clearRoutes = this.clearRoutes.bind(this);
    window.hideRoutes = this.hideRoutes.bind(this); 
  },
  components: {
    GeoField,
    ResultItem
  },
  methods: {
    clearRoutes() {
     
      this.hideRoutes();
      
      let $ = window.jQuery;
      window.$("#main-content").removeClass("pushLeft");
      if ($) {
        $(".googleResults").slideUp(200, () => {
          this.bikeRoutes = null;
          this.busRoutes = null;
        });
      }
    },
    hideRoutes() {
window.$("#trajet").fadeOut(function(){
  window.$("#trajet").html("")
})
      this.directionsRenderer.setMap(null);
    },
    showRoute(response, index) {
      this.directionsRenderer.setMap(window.map);
      this.directionsRenderer.setDirections(response);
      this.directionsRenderer.setRouteIndex(index);
      let route = response.routes[index];
      let intro = `
<div class="close">&times;</div>
<div class="head">
            <span class="${ (response === this.bikeRoutes) ? 'icon-velo' : 'icon-autobus' }"></span><h3>${ (response === this.bikeRoutes) ? 'Vélo' : 'Transport en commun' }</h3>
              <div class="route">
              <strong>${this.displayTitle(route)}</strong>
              <div>
                <span class="data">${route.legs[0].distance.text}</span><span class="data">${this.getTemps(route)}</span>
              </div>
              <div class="copy">${route.copyrights}</div>
            </div>
            </div>`;

      let steps = response.routes[index].legs[0].steps.map(step => {
        let color =
          (step.transit &&
            step.transit.line &&
            step.transit.line.short_name &&
            step.transit.line.color) ||
          "#CCC";
        let lineNb = "";

        if (step.transit && step.transit.line && step.transit.line.short_name) {
          lineNb = `<div class="lineNumber" style="color: ${step.transit.line.text_color}; background: ${step.transit.line.color}">
          ${step.transit.line.short_name}
          </div>`;
        }

        let agencies =
          (step.transit &&
            step.transit.line &&
            step.transit.line.agencies &&
            step.transit.line.agencies
              .map(a => `<a href="${a.url}" target="_blank">${a.name}</a>`)
              .join("")) ||
          "";

        let detail = "";
        if (step.transit) {
          detail = ` <div class="transit">
              ${(step.transit.departure_stop &&
                `<div><span>Départ&nbsp;:&nbsp;</span>${step.transit.departure_stop.name}</div>`) ||
                ""}
              ${(step.transit.arrival_stop &&
                `<div><span>Arrivée&nbsp;:&nbsp;</span>${step.transit.arrival_stop.name}</div>`) ||
                ""}
          </div>`;
        }

        return `<li style="border-color: ${color}">${lineNb}
        <div class="${(step.transit && "instructions") || ""}">${
          step.instructions
        }</div>
        <small>${(step.travel_mode === "BICYCLING" && step.distance.text) ||
          ""}${agencies}</small>
        ${detail}
        </li>`;
      });
      let html = `

      ${intro}
<ul class="RechercheData">  
${steps.join("")}
</ul>
`;

      // Masquer les Marqueurs
      window.HideBornes();
      window.bikeLayer.setMap(null);
      window.RemoveAllMarkers();
      window.$(".activepicto").removeClass("activepicto");

      const $ = window.jQuery;
      $("#trajet").html(html);
      $("#trajet").fadeIn();
      if ($(window).width() < 992) {
        $("html, body").animate({
          scrollTop: $("#main-content").offset().top
        },300)
      }
    },
    changeFrom(place) {
      this.from = place;
      //  this.triggerCovoiturage()
    },
    changeTo(place) {
      this.to = place;
    },
    triggerCovoiturage() {
      if (this.from && this.to) {
        if (window.showCovoiturage) window.showCovoiturage();
        SearchFunction({ from: this.from, to: this.to }, this);
      }
    },
    ajustView() {
      const $ = window.jQuery;
      if ($) {
        $(".googleResults").show();
      }
    },
    triggerGoogleSearch() {
      if (this.from && this.to) {
        let directionsService = new window.google.maps.DirectionsService();

        directionsService.route(
          {
            origin: this.from,
            destination: this.to,
            travelMode: "BICYCLING",
            avoidTolls: true
          },
          (response, status) => {
            if (status === "OK") {
              this.bikeRoutes = response;
              this.ajustView();
              //
            }
          }
        );
        directionsService.route(
          {
            origin: this.from,
            destination: this.to,
            travelMode: "TRANSIT",
            avoidTolls: true,
            provideRouteAlternatives: true
          },
          (response, status) => {
            if (status === "OK") {
              this.busRoutes = response;
              this.ajustView();
            }
          }
        );
        window.$("#main-content").addClass("pushLeft");
      }
    },
    swi() {
      let from = this.from;
      let to = this.to;

      this.to = from;
      this.from = to;

      let fromVal = this.$refs.from.$el.childNodes[0].value;
      let toVal = this.$refs.to.$el.childNodes[0].value;

      this.$refs.to.$el.childNodes[0].value = fromVal;
      this.$refs.from.$el.childNodes[0].value = toVal;
    },
    displayTitle(item) {
      let resume = item.legs[0].steps
        .reduce((acc, item) => {
          item &&
            item.transit &&
            item.transit.line &&
            item.transit.line.agencies &&
            item.transit.line.agencies.forEach(ag => {
              if (acc.indexOf(ag.name) === -1) acc.push(ag.name);
            });
          return acc;
        }, [])
        .join(", ");
      resume = resume ? resume : item.summary;
      return resume;
    },
    getTemps(item) {
      let minutesTotales = item.legs[0].duration.value / 60;
      let heures = (minutesTotales - (minutesTotales % 60)) / 60;
      let minutes = Math.floor(minutesTotales % 60);
      let tempsString = "";
      if (heures > 0) {
        tempsString += heures + "h";
      }
      if (minutes < 10 && heures > 0) {
        tempsString += "0";
      }
      tempsString += minutes;
      if (minutes === 1) {
        tempsString += " min";
      } else {
        tempsString += " mins";
      }
      return tempsString;
    }
  },
  computed: {
    section() {
      if (this.tri === "chrono") {
        return this.results.reduce((acc, value) => {
          let id = moment(value.jour).format("L");
          let label = moment(value.jour).format("LL");

          let event = this.results.filter(i => {
            let format = moment(i.jour).format("L");
            return format === id;
          });
          event = event.sort((a, b) => {
            let textA = a.jour;
            let textB = b.jour;
            return textA < textB ? -1 : textA > textB ? 1 : 0;
          });

          if (acc.filter(a => a.id === id).length === 0) {
            acc.push({
              label,
              id,
              event
            });
          }
          return acc;
        }, []);
      } else {
        return this.results.reduce((acc, value) => {
          let id = value.service;
          let label;

          switch (value.service) {
            case "eckoride":
              label = "EckoRide";
              break;
            case "amigo":
              label = "Amigo Express";
              break;
            case "ridesharing":
              label = "Covoiturage.ca";
              break;
            case "poparide":
              label = "Poparide";
              break;
          }

          if (acc.filter(a => a.id === id).length === 0) {
            let event = this.results.filter(i => {
              let format = i.service;
              return format === id;
            });
            event = event.sort((a, b) => {
              let textA = a.jour;
              let textB = b.jour;
              return textA < textB ? -1 : textA > textB ? 1 : 0;
            });
            acc.push({
              label,
              id,
              event
            });
          }
          return acc;
        }, []);
      }
    }
  }
};
</script>

<style>
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
