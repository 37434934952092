export default function (data, object) {
    const jQuery = window.jQuery;
    const $ = window.jQuery;

    String.prototype.hashCode = function () {
        var hash = 0;
        if (this.length == 0) {
            return hash;
        }
        for (var i = 0; i < this.length; i++) {
            var char = this.charCodeAt(i);
            hash = ((hash << 5) - hash) + char;
            hash = hash & hash; // Convert to 32bit integer
        }
        return hash;
    }
    const endpoint = window.covoiturageEndpoint;
    object.loading = 4;
    let results = []

    // POPARIDE REQUEST
    jQuery.ajax({
        'url': endpoint,
        'data': Object.assign({
            "service": "poparide"
        }, data),
        'success': function (data) {
            $(data + "").find(".trip-item").each(function () {
                let parent = $(this);
                let from = $(this).find(".trip-item-details-pickups-value").eq(0).text()
                let to = $(this).find(".trip-item-details-pickups-value").eq(1).text()
                parent.find(".trip-item-details-leaving, .trip-item-details-returning").each(function () {
                    let heure = $(this).text()
                    if (heure) {
                        heure = heure.match(/\d{1,2}h\d{2}/g)
                        heure = heure.length > 0 ? heure[0] : null
                        let retour = $(this).is(".trip-item-details-returning")
                        heure = heure.replace("h", ":")
                        let date = $(this).attr("content");

                        if (!date) {
                            date = $(this).parent().find("[content]").attr("content")
                        }
                        results.push({
                            service: 'poparide',
                            url: "http://www.poparide.com" + parent.data('url'),
                            from: (retour) ? to : from,
                            to: (retour) ? from : to,
                            jour: date + " " + heure,
                            places: parseInt(parent.find(".trip-item-cta-seats").text()),
                            prix: parseFloat(parent.find(".trip-item-cta-price").text()),
                        })
                    }
                })
            })
            results.forEach((item) => {
                item.id = Math.floor(Math.random() * 800000)
            })
            results = results.sort((a, b) => {
                if (a.jour > b.jour) return 1
                if (a.jour < b.jour) return -1
                return 0;
            })
            object.results = results.map(i => i)
        },
        complete: function () {

            object.loading--;
        }
    })

    // AMIGO REQUEST
    jQuery.ajax({
        'url': endpoint,
        'data': Object.assign({
            "service": "amigo"
        }, data),
        'success': function (data) {
            let currentDate = null;
            let outside = false;
            let jour = "";
            $(data + "").find('#content>#rideTable tr, #searchTitle').each(function () {
                if ($(this).is("#searchTitle")) {
                    outside = true;
                }
                if (!outside) {

                    if ($(this).find('.dateRow').length) {
                        let date = $(this).find('.dateRow').text().replace(/^\s*/gi, '').replace(/\s*$/gi, '');
                       console.log(date)
                        let mois="AA";
                        if (date.match(/anvier/)) {
                            mois = "01"
                        } else if (date.match(/vrier/)) {
                            mois = "02"
                        } else if (date.match(/ars/)) {
                            mois = "03"
                        } else if (date.match(/vril/)) {
                            mois = "04"
                        } else if (date.match(/ai/)) {
                            mois = "05"
                        } else if (date.match(/uin/)) {
                            mois = "06"
                        } else if (date.match(/uillet/)) {
                            mois = "07"
                        } else if (date.match(/Ao/)) {
                            mois = "08"
                        } else if (date.match(/eptembre/)) {
                            mois = "09"
                        } else if (date.match(/ctobre/)) {
                            mois = "10"
                        } else if (date.match(/ovembre/)) {
                            mois = "11"
                        } else if (date.match(/cembre/)) {
                            mois = "11"
                        }


                        let year = new Date().getFullYear()
                        if (new Date().getMonth() + 1 < parseInt(mois)) year++;

                        
                        jour = date.match(/\d?\d/)


                        if (jour < 10) jour = "0"+date[1];
                        jour = jour ? jour[0] : "AA";
                        currentDate = year + "-" + mois + "-" + jour;
                        console.log(currentDate)
                    } else {
                        let heure = $(this).find(".datetime").text().replace(/^\s*/gi, '').replace(/\s*$/gi, '');
                        heure = heure.match(/\d{1,2}:\d{2}/g)
                        if (heure) {
                            heure = heure.length > 0 ? heure[0] : null
                            heure = heure.length > 4 ? heure : "0"+heure

                            
                            let from = [$(this).find(".city.departure a").text().replace(/^\s*/gi, '').replace(/\s*$/gi, ''), $(this).find(".city.departure span").text().replace(/^\s*/gi, '').replace(/\s*$/gi, '')];
                            let to = [$(this).find(".city.destination a").text().replace(/^\s*/gi, '').replace(/\s*$/gi, ''), $(this).find(".city.destination span").text().replace(/^\s*/gi, '').replace(/\s*$/gi, '')];

                            let totales = parseInt($(this).find(".itineraryPrice").find("img[alt*=' Man']").length)
                            if (totales) {

                                jour = currentDate + " " + heure;
                                console.log(jour)
                                if (jour.match(/^\d{4}-\d{2}-\d{2} \d{2}:\d{2}$/)) {
                                    results.push({
                                        service: 'amigo',
                                        url: $(this).find(".city.departure a").attr('href'),
                                        from: from.filter(i => i).join(', '),
                                        to: to.filter(i => i).join(', '),
                                        jour,
                                        prix: parseFloat($(this).find(".itineraryPrice a").text().replace(/^\s*/gi, '').replace(/\s*$/gi, '')),
                                        places: parseInt($(this).find(".itineraryPrice").find("img[alt='White Man']").length),
                                        placesTotales: totales
                                    })
                                }
                                
                            }
                        }
                    }
                }

            })
            results.forEach((item) => {
                item.id = Math.floor(Math.random() * 800000)
            })
            results = results.sort((a, b) => {
                if (a.jour > b.jour) return 1
                if (a.jour < b.jour) return -1
                return 0;
            })
            object.results = results.map(i => i)
        },
        complete: function () {

            object.loading--;
        }

    })

    // Covoiturage.ca
    jQuery.ajax({
        'url': endpoint,
        'data': Object.assign({
            "service": "ridesharing"
        }, data),
        'dataType': 'json',
        'success': function (data) {
            results = results.concat(data)
            results.forEach((item) => {
                item.id = Math.floor(Math.random() * 800000)
            })
            results = results.sort((a, b) => {
                if (a.jour > b.jour) return 1
                if (a.jour < b.jour) return -1
                return 0;
            })
            object.results = results.map(i => i)
        },
        complete: function () {

            object.loading--;
        }
    })


    // Ecko Ride
    jQuery.ajax({
        'url': endpoint,
        'data': Object.assign({
            "service": "eckoride"
        }, data),
        'dataType': 'json',
        'success': function (data) {
            results = results.concat(data)
            results.forEach((item) => {
                item.id = Math.floor(Math.random() * 800000)
            })
            results = results.sort((a, b) => {
                if (a.jour > b.jour) return 1
                if (a.jour < b.jour) return -1
                return 0;
            })
            object.results = results.map(i => i)
        },
        complete: function () {

            object.loading--;
        }
    })
}