<template>
  <div>
    <input type="text" ref="field" class="form-control form-control-sm" :placeholder="placeholder" />
  </div>
</template>

<script>
function getBounds() {
   if (!window.google || !window.google.maps || !window.google.maps.LatLngBounds) return false;

  let bounds =
    (window.map && window.map.getBounds && window.map.getBounds()) ||
    new window.google.maps.LatLngBounds();
  bounds.extend(new window.google.maps.LatLng(49.1448278, -79.5057691));
  bounds.extend(new window.google.maps.LatLng(44.6492617, -63.6569935));
  return bounds;
}
export default {
  name: "GeoField",
  props: {
    msg: String,
    placeholder: String
  },
  methods: {
    foundPlace(place) {
      let province = null;
      let ville = null;
      let lat = null;
      let lng = null;
      if (place.address_components) {
        province = place.address_components.filter(
          i => i.types.indexOf("administrative_area_level_1") > -1
        )[0].short_name;
        ville =
          place.address_components.filter(
            i => i.types.indexOf("locality") > -1
          )[0] &&
          place.address_components.filter(
            i => i.types.indexOf("locality") > -1
          )[0].short_name;
        (lat = place.geometry.location.lat()),
          (lng = place.geometry.location.lng());
        ville =
          (ville && ville.toLowerCase()) ||
          (province && province.toLowerCase() === "qc" && "quebec") ||
          "";
        province = (province && province.toLowerCase()) || "";
      }
      if (ville === "quebec city") {
        ville = "quebec";
      }
      this.$emit("change", {
        province,
        ville,
        lat,
        lng
      });
    }
  },
  mounted() {
    const google = window.google;
    let autocomplete = new google.maps.places.Autocomplete(this.$refs.field, {
   //   types: ["(cities)"],
      bounds: getBounds(),
      strictBounds: true
    });
    google.maps.event.addListener(window.map, "init", function() {
      autocomplete.setOptions({
     //   types: ["(cities)"],
        bounds: getBounds(),
        strictBounds: true
      });
    });
    google.maps.event.addListener(window.map, "bounds_changed", function() {
      autocomplete.setOptions({
   //     types: ["(cities)"],
        bounds: getBounds(),
        strictBounds: true
      });
    });
    this.$refs.field.onfocus = () => {
      document.querySelectorAll(".pac-container").forEach(e => {
        e.innerHTML = "";
      });
    };

    this.$refs.field.onblur = (e) => {
      var place = autocomplete.getPlace();
      if (!place) {
        var service = new google.maps.places.AutocompleteService();
        let q = document.querySelector(".pac-container .pac-item-query");
        let query = e.target.value;
        if (q) {
          query = q.innerText + " " + q.nextElementSibling.innerText;
        }
        service.getPlacePredictions(
          {
            types: ["(cities)"],
            bounds: getBounds(),
            strictBounds: true,
            input: query
          },
          (predictions, status) => {
            if (status == "OK" && predictions && predictions[0]) {
              this.$refs.field.value = predictions[0].description;

              let detail = new google.maps.places.PlacesService(window.map);
              detail.getDetails(
                {
                  fields: ["geometry.location", "address_components"],
                  placeId: predictions[0].place_id
                },
                r => {
                  this.foundPlace(r);
                }
              );

              //
            }
          }
        );
      }
    };

    autocomplete.addListener("place_changed", () => {
      var place = autocomplete.getPlace();

      if (!place.geometry) {
        let geocoder = new google.maps.Geocoder();
        geocoder.geocode(
          {
            address:
              this.$refs.field.value +
              (this.$refs.field.value.split(" ").length < 2 ? ",Qc" : "")
          },
          (results, status) => {
            if (status == "OK" && results[0]) {
              this.foundPlace(results[0]);
              this.$refs.field.value = results[0].formatted_address;
            } else {
              this.$emit("change", {});
            }
          }
        );
      } else {
        this.foundPlace(place);
      }
    });
  }
};
</script>